/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*TODO: validate this*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    pre: "pre",
    code: "code",
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Get our current prompt"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "echo $PS1\n")), "\n", React.createElement(_components.p, null, "Our prompt is defined in our shell profile file. For example ", React.createElement(_components.code, null, ".bashrc"), " or ", React.createElement(_components.code, null, ".zshrc"), ". Either navigate to where the rc file is located. Normally in the user root, or use the following command to find the file."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "grep PS1 ~/.bash_profile\n")), "\n", React.createElement(_components.h2, null, "Common Prompts"), "\n", React.createElement(_components.h3, null, "User@Hostname"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "export PS1=\"\\u@\\h\"\n")), "\n", React.createElement(_components.p, null, "or edit the ", React.createElement(_components.code, null, ".bashrc"), " or ", React.createElement(_components.code, null, ".zshrc"), " file directly"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt:title=\".bashrc\""
  }, "# ...\nPS1=\"\\u@\\h\"\n# ...\n")), "\n", React.createElement(_components.h3, null, "Differentiate Root User form Normal User"), "\n", React.createElement(_components.p, null, "A common use case is to differentiate the root user from a normal user."), "\n", "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "export PS1=\"\\u@\\H \\W:\\$ \"\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "\n{/* TODO: \n- add the success fail promp refernce \n- add the git branch prompt reference\n- add the time prompt reference\n- add the user@hostname prompt reference\n- add the path prompt reference\n- add the custom prompt reference\n\n*/}\n\n## Reference \n\n[how to set up linux prompt - cyberciti.biz](https://www.cyberciti.biz/tips/howto-linux-unix-bash-shell-setup-prompt.html)\n\n[Prompt Customization - archilinux.org](https://wiki.archlinux.org/title/Bash/Prompt_customization)\n(Deep dive.)\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
